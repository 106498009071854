// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDONaFPuRVwQM11KAw4_lc-nEmqRxsH-D8",
  authDomain: "illusion-web-test.firebaseapp.com",
  projectId: "illusion-web-test",
  storageBucket: "illusion-web-test.appspot.com",
  messagingSenderId: "314649624526",
  appId: "1:314649624526:web:5d2d9a0983a212cd6d759f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;