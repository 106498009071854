import logo from './logo.svg';
import './App.css';
import Home from './pages/home/Home';
import { Route, Routes } from 'react-router-dom';
import Work from './pages/work/Work';
import About from './pages/about/About';
import ThreeD from './pages/threeD/ThreeD';
import TwoD from './pages/twoD/TwoD';
import Branding from './pages/branding/Branding';
import Web from './pages/web/Web';
import app from './firebase.init';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/home' element={<Home></Home>}></Route>
        <Route path='/work' element={<Work></Work>}></Route>
        <Route path='/about' element={<About></About>}></Route>
        <Route path='/threed' element={<ThreeD></ThreeD>}></Route>
        <Route path='/twod' element={<TwoD></TwoD>}></Route>
        <Route path='/branding' element={<Branding></Branding>}></Route>
        <Route path='/web' element={<Web></Web>}></Route>
      </Routes>
    </div>
  );
}

export default App;
