import React from 'react';
import logo from './images/IllusionLogoNavBlack.png';
import search from './images/search-icon.png';
import menu from './images/menu-icon.png';
import whiteIcon from './images/whiteIcon.png';
import menuClose from './images/menuClose.png';
import searchClose from './images/searchClose.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const HomeNav = () => {
    const [menuHandle, setMenuHandle] = useState('hidden');
    const [searchHandle, setSearchHandle] = useState('hidden');
    const openMenu = () => {
        setMenuHandle('block');
    }
    const closeMenu = () => {
        setMenuHandle('hidden');
    }
    const openSearch = () => {
        setSearchHandle('block');
    }
    const closeSearch = () => {
        setSearchHandle('hidden');
    }

    return (
        <div className='relative'>
            <div className='flex justify-center'>
                <div className='md:w-10/12 xl:w-8/12'>
                    <div className='flex justify-between items-center pr-6'>
                        <div className='flex pt-8 md:pt-10 w-full'>
                            <Link to='/'><img className='w-[45px] cursor-pointer' src={logo}></img></Link>
                            <p className='hidden md:block text-left font-light ml-4'>ILLUSION is a production house led by a<br></br> very enthusiastic and technologically sound<br></br> team, assisting 360° support to their clients<br></br> since 2018.</p>
                        </div>
                        <div className='flex items-center gap-8 text-lg md:gap-12 w-full justify-end'>
                            <Link to='/work' className='hidden md:block cursor-pointer hover:text-[#3941FF]'>Work</Link>
                            <Link to='/about' className='hidden md:block cursor-pointer hover:text-[#3941FF]'>About</Link>
                            <img className='w-[30px] cursor-pointer' src={search} alt='Search' onClick={openSearch}></img>
                            <img className='w-[25px] cursor-pointer' src={menu} alt='Menu' onClick={openMenu}></img>
                        </div>
                    </div>
                    <div className='text-left px-4 pt-2 font-light text-sm md:hidden'>
                        <p>ILLUSION is a production house led by a very enthusiastic and technologically sound team, assisting 360° support to their clients since 2018.</p>
                    </div>
                </div>
            </div>
            {/* menu */}
            <div className={`bg-[#433936] fixed top-0 left-0 right-0 flex justify-center ${menuHandle}`}>
                <div className='w-full md:w-10/12 xl:w-8/12 pt-8 pb-16'>
                    <div className='flex justify-between w-full px-4'>
                        <img className='w-[50px] h-full' src={whiteIcon} alt='Logo'></img>
                        <img className='w-[50px] h-full cursor-pointer' src={menuClose} alt='close' onClick={closeMenu}></img>
                    </div>
                    <div className='flex justify-center md:grid grid-cols-2 mt-8'>
                        <div></div>
                        <div className='flex flex-col text-white text-left text-4xl md:text-6xl font-thin gap-6'>
                            <Link to='/work' className='cursor-pointer hover:text-[#3941FF]'>Work</Link>
                            <Link to='/about' className='cursor-pointer hover:text-[#3941FF]'>About</Link>
                        </div>
                    </div>
                    <div className='flex justify-center md:grid grid-cols-2 mt-20'>
                        <div></div>
                        <div className='flex justify-between text-white w-full px-8 md:px-0 font-thin'>
                            <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Instagram</Link>
                            <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Facebook</Link>
                            <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Youtube</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* search */}
            <div className={`bg-[#EBAD4F] fixed top-0 left-0 right-0 flex justify-center ${searchHandle}`}>
                <div className='w-full md:w-10/12 xl:w-8/12 py-8'>
                    <div className='flex justify-between w-full px-4'>
                        <img className='w-[50px] h-full' src={logo} alt='Logo'></img>
                        <img className='w-[50px] h-full cursor-pointer' src={searchClose} alt='close' onClick={closeSearch}></img>
                    </div>
                    <div className='px-8 mt-24'>
                        <div className='flex justify-center items-center border-[1px] p-2 border-gray-600'>
                            <input style={{ border: 'none', background: 'none' }} className='w-full' placeholder='Search...'></input>
                            <img className='w-[20px] h-full' src={search}></img>
                        </div>
                    </div>
                    <div className='px-8 mt-8 mb-24'>
                        <div className='text-left grid gap-4 font-thin w-full md:w-3/4 xl:w-1/2'>
                            <div className='grid grid-cols-3 gap-4'>
                                <a >3D</a>
                                <a >2D</a>
                                <a >Branding</a>
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <a >Web Design</a>
                                <a >Social Media</a>
                                <a >Live Action</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeNav;