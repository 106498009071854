import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import whiteIcon from './images/whiteIcon.png';
import searchIcon from './images/searchIcon.png';
import menuIcon from './images/menuIcon.png';
import work1 from './images/work-1.jpg';
import work2 from './images/work-2.jpg';
import work3 from './images/work-3.jpg';
import work4 from './images/work-4.jpg';
import logo from '../home/images/IllusionLogoBlack.png';
import menuClose from '../home/images/menuClose.png';
import searchClose from '../home/images/searchClose.png';
import search from '../home/images/search-icon.png';
import logoNav from '../home/images/IllusionLogoNavBlack.png';

const Work = () => {
    const [menuHandle, setMenuHandle] = useState('hidden');
    const [searchHandle, setSearchHandle] = useState('hidden');
    const openMenu = () => {
        setMenuHandle('block');
    }
    const closeMenu = () => {
        setMenuHandle('hidden');
    }
    const openSearch = () => {
        setSearchHandle('block');
    }
    const closeSearch = () => {
        setSearchHandle('hidden');
    }
    return (
        <div>
            <div className='bg-[#304848] flex justify-center pb-20'>
                <div className='w-full md:w-10/12 xl:w-8/12 px-2 mt-8 px-2'>
                    <div className='flex justify-between'>
                        <div>
                            <Link to='/'><img className='w-[45px] cursor-pointer' src={whiteIcon}></img></Link>
                        </div>
                        <div className='flex items-center gap-8 text-lg md:gap-12 w-full justify-end text-white'>
                            <Link className='cursor-pointer hover:text-[#EBAD4F]' to='/about'>About</Link>
                            <img className='w-[30px] cursor-pointer' src={searchIcon} onClick={openSearch}></img>
                            <img className='w-[25px] cursor-pointer' src={menuIcon} onClick={openMenu}></img>
                        </div>
                    </div>
                    <div className='px-8 mt-20 mb-24 text-white'>
                        <div className='text-left grid gap-4 font-thin w-full md:w-3/4 xl:w-1/2'>
                            <div className='grid grid-cols-3 gap-4'>
                                <a className='text-2xl font-semibold mb-8'>Work</a>
                                <a ></a>
                                <a ></a>
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <a >3D</a>
                                <a >2D</a>
                                <a >Branding</a>
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <a >Web Design</a>
                                <a >Social Media</a>
                                <a >Live Action</a>
                            </div>
                        </div>
                    </div>
                    <div className='grid gap-8 md:grid-cols-2'>
                        <img src={work1}></img>
                        <img src={work2}></img>
                        <img src={work3}></img>
                        <img src={work4}></img>
                    </div>
                </div>
            </div>
            {/* footer */}
            <div className='bg-[#D7D7D7] flex justify-center py-8'>
                <div className='w-full md:w-10/12 xl:w-8/12 flex flex-col md:flex-row px-4'>
                    <div>
                        <img className='w-4/12 md:w-2/12' src={logo} alt='Logo'></img>
                        <p className='text-left'>#10, South Bishil, Mirpur 1, Dhaka. <br></br>+8801680815042, illusioniaa@gmail.com</p>
                    </div>
                    <div className='mt-8 flex justify-center gap-12 text-lg'>
                        <a className='cursor-pointer hover:text-[#3941FF]'>Facebook</a>
                        <a className='cursor-pointer hover:text-[#3941FF]'>Instagram</a>
                        <a className='cursor-pointer hover:text-[#3941FF]'>Youtube</a>
                    </div>
                </div>
            </div>
            {/* menu */}
            <div className={`bg-[#433936] fixed top-0 left-0 right-0 flex justify-center ${menuHandle}`}>
                <div className='w-full md:w-10/12 xl:w-8/12 pt-8 pb-16'>
                    <div className='flex justify-between w-full px-4'>
                        <img className='w-[50px] h-full' src={whiteIcon} alt='Logo'></img>
                        <img className='w-[50px] h-full cursor-pointer' src={menuClose} alt='close' onClick={closeMenu}></img>
                    </div>
                    <div className='flex justify-center md:grid grid-cols-2 mt-8'>
                        <div></div>
                        <div className='flex flex-col text-white text-left text-4xl md:text-6xl font-thin gap-6'>
                            <Link to='/work' className='cursor-pointer hover:text-[#3941FF]'>Work</Link>
                            <Link to='/about' className='cursor-pointer hover:text-[#3941FF]'>About</Link>
                        </div>
                    </div>
                    <div className='flex justify-center md:grid grid-cols-2 mt-20'>
                        <div></div>
                        <div className='flex justify-between text-white w-full px-8 md:px-0 font-thin'>
                            <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Instagram</Link>
                            <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Facebook</Link>
                            <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Youtube</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* search */}
            <div className={`bg-[#EBAD4F] fixed top-0 left-0 right-0 flex justify-center ${searchHandle}`}>
                <div className='w-full md:w-10/12 xl:w-8/12 py-8'>
                    <div className='flex justify-between w-full px-4'>
                        <img className='w-[50px] h-full' src={logoNav} alt='Logo'></img>
                        <img className='w-[50px] h-full cursor-pointer' src={searchClose} alt='close' onClick={closeSearch}></img>
                    </div>
                    <div className='px-8 mt-24'>
                        <div className='flex justify-center items-center border-[1px] p-2 border-gray-600'>
                            <input style={{ border: 'none', background: 'none' }} className='w-full' placeholder='Search...'></input>
                            <img className='w-[20px] h-full' src={search}></img>
                        </div>
                    </div>
                    <div className='px-8 mt-8 mb-24'>
                        <div className='text-left grid gap-4 font-thin w-full md:w-3/4 xl:w-1/2'>
                            <div className='grid grid-cols-3 gap-4'>
                                <a >3D</a>
                                <a >2D</a>
                                <a >Branding</a>
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <a >Web Design</a>
                                <a >Social Media</a>
                                <a >Live Action</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Work;