import React from 'react';
import logo from './images/IllusionLogoBlack.png';
const HomeFooter = () => {
    return (
        <div className='bg-[#D7D7D7] flex justify-center mt-20 py-8'> 
            <div className='w-full md:w-10/12 xl:w-8/12 flex flex-col md:flex-row px-4'>
                <div>
                    <img className='w-4/12 md:w-2/12' src={logo} alt='Logo'></img>
                    <p className='text-left'>#10, South Bishil, Mirpur 1, Dhaka. <br></br>+8801680815042, illusioniaa@gmail.com</p>
                </div>
                <div className='mt-8 flex justify-center gap-12 text-lg'>
                    <a className='cursor-pointer hover:text-[#3941FF]'>Facebook</a>
                    <a className='cursor-pointer hover:text-[#3941FF]'>Instagram</a>
                    <a className='cursor-pointer hover:text-[#3941FF]'>Youtube</a>
                </div>
            </div>
        </div>
    );
};

export default HomeFooter;