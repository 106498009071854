import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../home/images/IllusionLogoNavBlack.png';
import search from '../home/images/search-icon.png';
import menu from '../home/images/menu-icon.png';
import whiteIcon from '../home/images/whiteIcon.png';
import menuClose from '../home/images/menuClose.png';
import searchClose from '../home/images/searchClose.png';
import './threeDStyle.css';
import leftArrow from './images/leftArrow.png'
import threed1 from './images/threed1.jpg';
import threed2 from './images/threed2.jpg';
import threed3 from './images/threed3.jpg';
import threed4 from './images/threed4.jpg';
import threed5 from './images/threed5.jpg';
import threed6 from './images/threed6.jpg';
import threed7 from './images/threed7.jpg';
import threed8 from './images/threed8.jpg';
import threed9 from './images/threed9.jpg';
import threed10 from './images/threed10.jpg';
import logoFooterWhite from './images/logoFooterWhite.png';

const ThreeD = () => {
    const [menuHandle, setMenuHandle] = useState('hidden');
    const [searchHandle, setSearchHandle] = useState('hidden');
    const openMenu = () => {
        setMenuHandle('block');
    }
    const closeMenu = () => {
        setMenuHandle('hidden');
    }
    const openSearch = () => {
        setSearchHandle('block');
    }
    const closeSearch = () => {
        setSearchHandle('hidden');
    }
    return (
        <div className='threeDGradient'>
            <div className='relative'>
                <div className='flex justify-center'>
                    <div className='md:w-10/12 xl:w-8/12 w-full'>
                        <div className='flex justify-between items-center pr-6'>
                            <div className='pt-8 md:pt-10 w-full'>
                                <Link to='/'><img className='w-[45px] cursor-pointer' src={logo}></img></Link>
                            </div>
                            <div className='flex items-center gap-8 text-lg md:gap-12 w-full justify-end'>
                                <Link to='/work' className='hidden md:block cursor-pointer hover:text-[#3941FF]'>Work</Link>
                                <Link to='/about' className='hidden md:block cursor-pointer hover:text-[#3941FF]'>About</Link>
                                <img className='w-[30px] cursor-pointer' src={search} alt='Search' onClick={openSearch}></img>
                                <img className='w-[25px] cursor-pointer' src={menu} alt='Menu' onClick={openMenu}></img>
                            </div>
                        </div>
                        <div className='px-2 mt-16'>
                            <div className='flex items-center gap-4'>
                                <Link to='/'><img src={leftArrow}></img></Link>
                                <div className='bg-[#266459] w-1/2 md:w-1/3 md:py-8 xl:py-12 text-white font-bold text-5xl md:text-6xl xl:text-8xl py-6'>3D</div>
                            </div>
                            <div className='grid gap-4 mt-8 md:grid-cols-2'>
                                <img src={threed9} alt='Image'></img>
                                <img src={threed10} alt='Image'></img>
                                <img src={threed1} alt='Image'></img>
                                <img src={threed2} alt='Image'></img>
                                <img src={threed3} alt='Image'></img>
                                <img src={threed4} alt='Image'></img>
                                <img src={threed5} alt='Image'></img>
                                <img src={threed6} alt='Image'></img>
                                <img src={threed7} alt='Image'></img>
                                <img src={threed8} alt='Image'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* menu */}
                <div className={`bg-[#433936] fixed top-0 left-0 right-0 flex justify-center ${menuHandle}`}>
                    <div className='w-full md:w-10/12 xl:w-8/12 pt-8 pb-16'>
                        <div className='flex justify-between w-full px-4'>
                            <img className='w-[50px] h-full' src={whiteIcon} alt='Logo'></img>
                            <img className='w-[50px] h-full cursor-pointer' src={menuClose} alt='close' onClick={closeMenu}></img>
                        </div>
                        <div className='flex justify-center md:grid grid-cols-2 mt-8'>
                            <div></div>
                            <div className='flex flex-col text-white text-left text-4xl md:text-6xl font-thin gap-6'>
                                <Link to='/work' className='cursor-pointer hover:text-[#3941FF]'>Work</Link>
                                <Link to='/about' className='cursor-pointer hover:text-[#3941FF]'>About</Link>
                            </div>
                        </div>
                        <div className='flex justify-center md:grid grid-cols-2 mt-20'>
                            <div></div>
                            <div className='flex justify-between text-white w-full px-8 md:px-0 font-thin'>
                                <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Instagram</Link>
                                <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Facebook</Link>
                                <Link to='/' className='cursor-pointer hover:text-[#3941FF]'>Youtube</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* search */}
                <div className={`bg-[#EBAD4F] fixed top-0 left-0 right-0 flex justify-center ${searchHandle}`}>
                    <div className='w-full md:w-10/12 xl:w-8/12 py-8'>
                        <div className='flex justify-between w-full px-4'>
                            <img className='w-[50px] h-full' src={logo} alt='Logo'></img>
                            <img className='w-[50px] h-full cursor-pointer' src={searchClose} alt='close' onClick={closeSearch}></img>
                        </div>
                        <div className='px-8 mt-24'>
                            <div className='flex justify-center items-center border-[1px] p-2 border-gray-600'>
                                <input style={{ border: 'none', background: 'none' }} className='w-full' placeholder='Search...'></input>
                                <img className='w-[20px] h-full' src={search}></img>
                            </div>
                        </div>
                        <div className='px-8 mt-8 mb-24'>
                            <div className='text-left grid gap-4 font-thin w-full md:w-3/4 xl:w-1/2'>
                                <div className='grid grid-cols-3 gap-4'>
                                    <a >3D</a>
                                    <a >2D</a>
                                    <a >Branding</a>
                                </div>
                                <div className='grid grid-cols-3 gap-4'>
                                    <a >Web Design</a>
                                    <a >Social Media</a>
                                    <a >Live Action</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            <div className='bg-[#266459] flex justify-center mt-20 py-8 text-white'>
                <div className='w-full md:w-10/12 xl:w-8/12 flex flex-col md:flex-row px-4'>
                    <div>
                        <img className='w-4/12 md:w-2/12' src={logoFooterWhite} alt='Logo'></img>
                        <p className='text-left'>#10, South Bishil, Mirpur 1, Dhaka. <br></br>+8801680815042, illusioniaa@gmail.com</p>
                    </div>
                    <div className='mt-8 flex justify-center gap-12 text-lg'>
                        <a className='cursor-pointer hover:text-[#222323]'>Facebook</a>
                        <a className='cursor-pointer hover:text-[#222323]'>Instagram</a>
                        <a className='cursor-pointer hover:text-[#222323]'>Youtube</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeD;