import React from 'react';
import './homeStyle.css';
import threeD from './images/threeD.jpg';
import twoD from './images/twoD.jpg';
import brand from './images/brand.jpg';
import web from './images/web.jpg';
import HomeFooter from './HomeFooter';
import HomeNav from './HomeNav';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div>
            <HomeNav></HomeNav>
            <div className='mx-2 flex justify-center mt-12'>
                <div className='md:w-10/12 xl:w-8/12'>
                    <div>
                        <div className='flex flex-col md:flex-row gap-4 justify-end'>
                            <div className='order-2 md:order-1 md:w-9/12 xl:w-8/12'>
                                <img src={threeD} alt='3D image'></img>
                                <div className='flex justify-between text-sm md:text-lg font-light mt-2'>
                                    <p>Work</p>
                                    <p className='text-right w-9/12 md:w-7/12 xl:w-1/2'>3D asset building to 3D animation projects with super high quality render</p>
                                </div>
                            </div>
                            <div className='flex justify-end order-1 md:order-2 md:w-3/12 h-1/3'>
                                <Link to='/threed' className='text-4xl md:text-7xl font-black homeGradient w-1/2 md:w-full py-8 xl:py-10 '>3D</Link>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-4 mt-12'>
                            <div className='flex justify-start md:w-3/12 h-1/3'>
                                <Link to='/twod' className='text-4xl md:text-7xl font-black bg-[#FF8412] w-1/2 md:w-full py-8 xl:py-10 '>2D</Link>
                            </div>
                            <div className='md:w-9/12 xl:w-8/12'>
                                <img src={twoD} alt='3D image'></img>
                                <div className='flex justify-between flex-row-reverse text-sm md:text-lg font-light mt-2'>
                                    <p>Work</p>
                                    <p className='text-left w-9/12 md:w-7/12 xl:w-1/2'>2D illustration, painting, Frame-by-frame traditional animation</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-4 mt-12 justify-end'>
                            <div className='order-2 md:order-1 md:w-9/12 xl:w-8/12'>
                                <img src={brand} alt='3D image'></img>
                                <div className='flex justify-between text-sm md:text-lg font-light mt-2'>
                                    <p>Work</p>
                                    <p className='text-right w-9/12 md:w-7/12 xl:w-1/2'>Creating brand identity from literally scratch</p>
                                </div>
                            </div>
                            <div className='flex justify-end order-1 md:order-2 md:w-3/12 h-1/3'>
                                <Link to='/branding' className='text-4xl md:text-7xl font-black bg-[#3941FF] text-white w-1/2 md:w-full py-8 xl:py-10 '>Brand</Link>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-4 mt-12'>
                            <div className='flex justify-start md:w-3/12 h-1/3'>
                                <Link to='/web' className='text-4xl md:text-7xl font-black bg-[#B1E8FF]  w-1/2 md:w-full py-8 xl:py-10 '>Web</Link>
                            </div>
                            <div className='md:w-9/12 xl:w-8/12'>
                                <img src={web} alt='3D image'></img>
                                <div className='flex justify-between flex-row-reverse text-sm md:text-lg font-light mt-2'>
                                    <p>Work</p>
                                    <p className='text-left w-9/12 md:w-7/12 xl:w-1/2'>Raw coded wedsite designing with modern UI and successfull UX</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter></HomeFooter>
        </div>
    );
};

export default Home;